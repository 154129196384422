<template>
  <div class="form_setting_component_list">
    <div v-if="type===1" class="form_component_grid">
      <div class="form_component_item form_component_add cursor-pointer" v-if="actionPermissionMap['systemConfig:workOrder:component:add']" @click="dialogVisible=true">
          <i class="el-icon-plus text-primary "></i>
        </div>
      <div class="form_component_item"
        v-for="i in componentList"
        :key="i.id">
          <div>
            <span>
              {{i.name}}
              <i class="icon iconfont icon-bianji1 text-disable cursor-pointer ml-1" v-auth="'systemConfig:workOrder:component:edit'"   @click="handleEditName(i)"></i>
            </span>
            <span>
              <span :class="{'text-disable':!i.status,'text-primary':i.status,'mr-3':true}">{{i.status?'启用':'未启用'}}</span>
              <el-switch size="mini"
                :disabled="!actionPermissionMap['systemConfig:workOrder:component:use']"
                :value="i.status"
                @change="handleEnable(i,$event)">
              </el-switch>
            </span>
          </div>
          <div>
              <el-tag :type="i.useFlag?'primary':'info'" effect="plain"  size="mini">{{i.useFlag?'已使用':'未使用'}}</el-tag>
          </div>
          <div :title="i.customizeBusinessTypeResDTOList && i.customizeBusinessTypeResDTOList.map(t=>t.name).join(',') || '--'">
            <span>启用类型：{{i.customizeBusinessTypeResDTOList && i.customizeBusinessTypeResDTOList.map(t=>t.name).join(',') || '--'}}</span>
          </div>
          <div>
            <span>最近更新：{{i.updateUserName}} {{i.lastUpdateTime | date-format}}</span>
          </div>
          <div>
            <span>
              <i title="编辑" class="icon iconfont icon-xiugai text-primary cursor-pointer" v-auth="'systemConfig:workOrder:component:edit'"  @click="handleEdit(i)"></i>
              <i title="删除" class="icon iconfont icon-shanchu ml-2 text-danger cursor-pointer" v-auth="'systemConfig:workOrder:component:del'"  v-if="!i.useFlag && !i.status" @click="handleDelete(i)"></i>
            </span>
          </div>
        </div>
    </div>
    <div v-else class="form_category_list">
      <div>
        <div class="form_component_item"
          v-for="i in componentList"
          :key="i.id">
            <div>
              <span>
                <span>
                  {{i.name}}
                  <i class="icon iconfont icon-bianji1  text-disable cursor-pointer ml-1" v-auth="'systemConfig:workOrder:component:edit'"   @click="handleEditName(i)"></i>
                </span>
                <el-tag :type="i.useFlag?'primary':'info'" effect="plain"  size="mini" class="ml-2">{{i.useFlag?'已使用':'未使用'}}</el-tag>
              </span>
              <span class="text-ellipsis">
                <el-tooltip  :content="i.customizeBusinessTypeResDTOList && i.customizeBusinessTypeResDTOList.map(t=>t.name).join(',') || '--'" placement="top-start">
                <span>启用类型：{{i.customizeBusinessTypeResDTOList && i.customizeBusinessTypeResDTOList.map(t=>t.name).join(',') || '--'}}</span>
                </el-tooltip>
              </span>
            </div>
            <div>
              <span>
                <span :class="{'text-disable':!i.status,'text-primary':i.status,'mr-3':true}">{{i.status?'启用':'未启用'}}</span>
                <el-switch size="mini"
                  :disabled="!actionPermissionMap['systemConfig:workOrder:type:use']"
                  :value="i.status"
                  @change="handleEnable(i,$event)">
                </el-switch>
              </span>
              最近更新：{{i.updateUserName}} {{i.lastUpdateTime| date-format}}
            </div>
            <div>
              <span>
                <el-link type="primary"  v-auth="'systemConfig:workOrder:component:edit'"    @click.native="handleEdit(i)">编辑</el-link>
                <el-link type="danger" v-auth="'systemConfig:workOrder:component:del'"  v-if="!i.useFlag && !i.status" class="ml-2" @click.native="handleDelete(i)">删除</el-link>
              </span>
            </div>
        </div>
      </div>
      <div class="form_component_add" v-if="actionPermissionMap['systemConfig:workOrder:component:add']">
        <div class="cursor-pointer" @click="dialogVisible=true">
          <i class="el-icon-plus text-primary " >新增</i>
        </div>
      </div>
    </div>
    <el-dialog
      :title="`${templateForm && templateForm.code ?'修改':'新增'}业务组件${templateForm && templateForm.code ?'名称':''}`"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="460px"
      @close="handleDialogClose">
      <el-form :model="templateForm" :rules="templateFormRules" ref="templateForm" label-width="100px">
        <el-form-item label="组件名称" prop="name">
          <el-input v-model="templateForm.name" placeholder="请填写组件名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveNewTemplate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getComponentListByConfigId,
  deleteComponent,
  addComponent,
  updateComponentName,
  updateComponentStatus,
} from '@/api/formSetting';
import { mapGetters } from 'vuex';

export default {
  name: 'form_setting_component_list',
  props: {
    type: {
      type: Number,
      default: 1,
    },
    configId: String,
    configCode: String,
    configName: String,
  },
  data () {
    return {
      componentList: [],
      dialogVisible: false,
      templateForm: {
        code: null,
        name: '',
      },
      templateFormRules: {
        name: [
          { message: '请填写组件名称', required: true, trigger: ['blur', 'change']},
          { message: '组件名称最大10个字符', max: 10, trigger: ['blur', 'change']},
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'actionPermissionMap',
    ]),
  },
  methods: {
    handleEditName (i) {
      let {name, code, id} = i;
      this.templateForm = {name, id, code };
      this.dialogVisible = true;
    },
    handleDisaplyTabChanged (tabIndex) {
      this.activeTab = tabIndex;
      this.getComponentList();
    },
    handleDialogClose () {
      this.$refs.templateForm.resetFields();
      this.templateForm = {
        code: null,
        name: '',
      };
    },
    handleSaveNewTemplate () {
      this.$refs.templateForm.validate(valid=>{
        if (valid) {
          let action = null;
          if (this.templateForm.code) {
            action = updateComponentName({...this.templateForm});
          } else {
            action = addComponent({
              ...this.templateForm,
              customizeSystemConfigId: this.configId,
              customizeSystemConfigCode: this.configCode,
              customizeSystemConfigName: this.configName,
            });
          }
          action.then(()=>{
            this.dialogVisible = false;
            this.getComponentList();
          });
        }
      });
    },
    handleEdit (i) {
      this.$router.push({
        name: 'FORM_SETTING_COMPONENT',
        query: {
          id: i.id,
        },
      });
    },
    handleDelete (i) {
      this.$confirm(`即将删除业务组件 "${i.name}"，是否确认？`, '删除业务组件', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
      }).then(()=>{
        deleteComponent(i.id).then(()=>{
          this.getComponentList();
        });
      });
    },
    handleEnable (i, value) {
      updateComponentStatus({id: i.id, status: value}).then(()=>{
        this.getComponentList();
      });
    },
    getComponentList () {
      getComponentListByConfigId(this.configCode).then(ret=>{
        this.componentList = ret.body;
      });
    },
  },
  watch: {
    configCode () {
      if (this.configCode) {
        this.getComponentList();
      }
    },
  },
  created () {
    if (this.configCode) {
      this.getComponentList();
    }
  },
};
</script>
<style lang="scss" scoped>
.form_setting_component_list {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  overflow-y: auto;

  .form_component_grid {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 314px);
    grid-template-rows: repeat(auto-fill, 161px);
    grid-gap: 16px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .form_component_item {
      position: relative;
      font-size: 14px;
      line-height: 1;
      box-sizing: border-box;
      width: 332px;
      // height: 161px;
      padding:19px 20px 22px;
      box-shadow: 0px 2px 4px 0px rgba(36,40,50,0.1000);
      border-radius: 4px;
      border: 1px solid rgba(113,121,141,0.0800);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition-duration: 0.3s;
      transition-property: transform;

      .iconfont{
        font-size: 14px;
      }

      &:hover {
       transform: scale(1.05);
      }

      &.form_component_add{
        justify-content: center;
        align-items: center;
        font-size: 36px;
      }
      &>div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

          &:nth-child(1){
            &>span:first-child{
              font-weight: bolder;
              color: #242832;
            };
            justify-content: space-between;

            /deep/ .el-switch {
              height: 14px;
              line-height: 14px;

              .el-switch__core {
                height: 14px;
                width: 32px!important;

                &:after {
                  height: 12px;
                  width: 12px;
                  top: 0;
                }
              }

              &.is-checked {
                .el-switch__core:after{
                  left:auto;
                  right: 0;
                }
              }
            }
          }

          &:nth-child(2){
            padding-top: 12px;
            padding-bottom: 15px;

            /deep/ .el-tag  {
              border-radius: 2px;
            }
          }
          &:nth-child(3),&:nth-child(4) {
            font-weight: 400;
            color: rgba(36,40,50,0.7000);
            &>span{
              display: block;
              text-overflow:ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

          }

          &:nth-child(4){
            padding-top: 15px;
            padding-bottom: 12px;
          }

          &:nth-child(5){
            position: absolute;
            right: 30px;
            bottom: 10px;
            justify-content: flex-end;
            font-size: 16px;
          }
      }
    }
  }

  .form_category_list {
    box-shadow: 0px 2px 4px 0px rgba(36,40,50,0.1000);
    border-radius: 4px;
    border: 1px solid rgba(113,121,141,0.0800);
    padding: 0 12px;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &>div {
      &:first-child{
        flex-shrink: 1;
        overflow-x: hidden;
        overflow-y: auto;
        .form_component_item{
          font-size: 14px;
          line-height: 1;
          font-weight: 400;
          color: rgba(43,38,48,0.6000);
          box-sizing: border-box;
          height: 85px;
          padding: 19px 0;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid  rgba(36,40,50,0.1600);
          border-radius: 1px;
          &:hover{
            // transform: translateX(12px);
            color: $primary-color;
          }

          .iconfont{
            font-size: 14px;
          }

          &>div:nth-child(1){
            width: 320px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &>span:nth-child(1){
              display: flex;
              flex-direction: row;
              align-items: center;
              &>span:first-child {
                font-weight: bolder;
              }
              color: #242832;
              /deep/ .el-tag {
                border-radius: 2px;
              }
            }
          }

          &>div:nth-child(2){
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            /deep/ .el-switch {
              height: 14px;
              line-height: 14px;

              .el-switch__core {
                height: 14px;
                width: 32px!important;

                &:after {
                  height: 12px;
                  width: 12px;
                  top: 0;
                }
              }

              &.is-checked {
                .el-switch__core:after{
                  left:auto;
                  right: 0;
                }
              }
            }
          }

          &>div:nth-child(3){
            width: 132px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }
        }
      }

      &.form_component_add {
        flex-grow: 1;
        display: flex;
        align-items:flex-start;
        height: 46px;
        line-height: 46px;
        width: 100%;
        background: white;
        &>div{
          width: 100%;
          text-align: center;
          border-bottom: 1px solid  rgba(36,40,50,0.1600);
        }
      }
    }

  }
}
</style>
