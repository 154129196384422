<template>
  <div class="form_setting_index">
    <content-block title="配置管理">
      <div class="config_category_list">
          <div v-for="i in configList"
            :key="i.id"
            @click="handleConfigChanged(i)"
            :class="`config_category_item ${i.id === selectedConfig ? 'active' : ''}`">
            {{i.name}}
          </div>
      </div>
    </content-block>
    <div></div>
    <div>
      <div class="d-flex flex-row  justify-content-between w-100  align-items-center">
        <div>
          <span @click="handleDisaplyTabChanged(0)" :class="{active:activeTab===0}">{{configName}}</span>
          <span @click="handleDisaplyTabChanged(1)" :class="{active:activeTab===1}">业务组件</span>
        </div>
        <span>
          <i @click="currentShowType=1" :class="`el-icon-s-grid font-large mr-1 cursor-pointer ${currentShowType==1?'text-primary':''}`"></i>
          <i @click="currentShowType=2" :class="`el-icon-s-fold font-large cursor-pointer ${currentShowType==2?'text-primary':''}`" ></i>
        </span>
      </div>
      <component :is="currentDisplay"
        :type="currentShowType"
        :configId="selectedConfig"
        :print="settingPrint"
        :isNewFlow="isNewFlow"
        :configCode="selectedConfigCode"
        :configName="configName"/>
    </div>
  </div>
</template>
<script>
import {
  getSysConfigList,
} from '@/api/formSetting';
import { mapGetters } from 'vuex';
import TemplateList from './components/templateList.vue';
import ComponentList from './components/componentList.vue';

export default {
  name: 'form_setting_index',
  components: {
    TemplateList,
    ComponentList,
  },
  data () {
    return {
      activeTab: 0,
      configList: [],
      selectedConfig: null,
      selectedConfigCode: null,
      settingPrint: false,
      configName: '工单类型',
      formCategoryList: [],
      currentShowType: 1,
      businessTypeList: [],
      isNewFlow: false,
    };
  },
  computed: {
    ...mapGetters([
      'actionPermissionMap',
    ]),
    currentDisplay () {
      if (this.activeTab === 0) {
        return 'TemplateList';
      } else {
        return 'ComponentList';
      }
    },
  },
  methods: {
    handleConfigChanged (i) {
      this.selectedConfig = i.id;
      this.selectedConfigCode = i.code;
      this.configName = i.abbreviation;
      this.settingPrint = i.printFlag;
      this.isNewFlow = i.customizeProcessFlag;
    },
    handleDisaplyTabChanged (tabIndex) {
      this.activeTab = tabIndex;
    },
  },
  created () {
    getSysConfigList().then(ret=>{
      this.configList = ret.body;
      if (this.configList && this.configList[0]) {
        this.selectedConfig = this.configList[0].id;
        this.selectedConfigCode = this.configList[0].code;
        this.configName = this.configList[0].abbreviation;
        this.settingPrint = this.configList[0].printFlag;
        this.isNewFlow = this.configList[0].customizeProcessFlag;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.form_setting_index {
  display: flex;
  height: 100%;
  flex-direction: row;

  &>div{
    height: 100%;
    box-sizing: border-box;
  }
  &>div:nth-child(1) {
    width: 160px;
    display: flex;
    flex-direction: column;

    .config_category_list {
      flex: 1;
      overflow: auto;
    }
    .config_category_item {
      font-size: 14px;
      font-weight: 400;
      color: rgba(36,40,50,0.7000);
      line-height: 14px;
      padding: 12px;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.active ,&:hover{
        background: rgba(36,40,50,0.1000);
      }
    }
  }
  &>div:nth-child(2) {
    width: 16px;
  }

  &>div:nth-child(3) {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;

    &>div:nth-child(1) {
      line-height: 36px;
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;

      &>div:nth-child(1) {
        &>span {
          font-size: 14px;
          display: inline-block;
          padding: 0 18px;
          cursor: pointer;
          position: relative;

          &.active{
            color: $primary-color;
            &:after{
              content: '';
              position: absolute;
              bottom: 0;
              left: calc(50% - 18px);
              width: 36px;
              height: 2px;
              background: $primary-color;
            }
          }
        }
      }
    }
  }
}
</style>
